<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="search_type_cm"
      :searchObj="searchType.obj"
      :useKeywordType="true"
      @change-search-type="$_changeSearchType"
      @onSearchClick="$_searchData($event, 'search_type_cm')"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes.siteId"
          placeholder="사이트 선택"
          :items="getSiteList"
          display-expr="siteNm"
          value-expr="id"
          :styling-mode="stylingMode"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('siteId', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="cmGrid" @init-new-row="onInitNewRow" />

    <DxPopup
      v-if="modal.isOpened"
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :width="modal.initData ? modal.initData.width : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <transition>
            <div class="page-sub-box">
              <table class="table_form line-bin">
                <colgroup>
                  <col style="width: 200px" />
                  <col style="width: auto" />
                </colgroup>

                <tbody>
                  <tr>
                    <th scope="row" class="alT"><label>비밀번호 *</label></th>
                    <td>
                      <DxTextBox
                        v-model="password"
                        :styling-mode="stylingMode"
                        :width="170"
                        style="box-shadow: none !important"
                        mode="password"
                      >
                        <DxValidator validation-group="ValidationGroupName">
                          <DxRequiredRule message="비밀번호는 필수입니다." />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" class="alT"><label>비밀번호 확인 *</label></th>
                    <td>
                      <DxTextBox
                        v-model="rePassword"
                        :styling-mode="stylingMode"
                        :width="170"
                        style="box-shadow: none !important"
                        mode="password"
                      >
                        <DxValidator validation-group="ValidationGroupName">
                          <DxRequiredRule message="비밀번호 확인은 필수입니다." />
                          <DxCustomRule :validation-callback="passwordCheck" message="비밀번호가 다릅니다." />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </transition>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.buttons.save.text,
          width: '120',
          height: '40',
          onClick: () => onSave(modal.sendData),
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.buttons.cancel.text,
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;
  export default {
    components: {
      EspDxDataGrid,
      DxTextBox,
      DxCustomRule,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      Search,
      DxPopup,
      DxToolbarItem,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        password: '',
        rePassword: '',
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            siteId: null,
          },
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'cmGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_CM_UPDATE',
            delete: 'EUC_CM_DELETE',
            loading: true,
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'Vender',
              dataField: 'vender',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.$_getCode('euc_vender_type'),
                    displayExpr: 'codeNm',
                    valueExpr: 'codeValue',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.cmGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '교환기 명',
              dataField: 'cmNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: 'IP',
              dataField: 'cmIp',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              cellTemplate: (container, options) => {
                if (options.value) {
                  const aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    window.open(`https://${options.value}`);
                  });
                  container.append(aTag);
                }
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '교환기 계정',
              dataField: 'userId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '비밀번호',
              dataField: 'passwd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    text: '비밀번호 변경',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    value: options.value,
                    onClick: () => this.onShowPasswordChange(options),
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: 'PIN 번호',
              dataField: 'pinPasswd',
              height: 40,
              alignment: 'center',
              visible: false,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사이트',
              dataField: 'siteId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              editorType: 'dxSelectBox',
              cellTemplate: (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.$store.getters.getSiteList,
                    displayExpr: 'siteNm',
                    valueExpr: 'id',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.cmGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                      const tenant = this.getTenantList(e.value)[0];
                      const newValue = tenant?.id || null;
                      this.$refs.cmGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex + 1, newValue);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: 'Tenant',
              dataField: 'tenantId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              editorType: 'dxSelectBox',
              cellTemplate: (container, options) => {
                const tenantList = this.getTenantList(options.data.siteId);
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: tenantList,
                    displayExpr: 'tenantNm',
                    valueExpr: 'id',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.cmGrid.getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
              requiredRule: {
                message: 'Tenant을 선택해주세요.',
              },
            },
            {
              caption: '순서',
              dataField: 'cmOrd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용 여부',
              dataField: 'viewFl',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedviewFl(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getSiteList() {
        const result = this.$store.getters.getSiteList;
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        if (params?.siteId) {
          this.setCustomTypes('siteId', params.siteId);
        } else {
          this.setCustomTypes('siteId', null);
        }

        return [{ id: null, siteNm: '전체' }, ...result];
      },
    },
    methods: {
      async onChangedviewFl(data) {
        let viewFl = data.viewFl === 'Y' ? 'N' : 'Y';
        if (data.id) {
          const payload = {
            actionname: 'EUC_CM_UPDATE',
            data: [
              {
                id: data.id,
                viewFl,
              },
            ],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
            this.$refs.cmGrid.getGridInstance.refresh();
          } else {
            this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          data.viewFl = viewFl;
          //  === 'Y';
        }
      },
      passwordCheck() {
        return this.password === this.rePassword;
      },
      onSave(data) {
        if (this.password) {
          if (this.password !== this.rePassword) {
            this.$_Msg('비밀번호가 다릅니다.');
          } else {
            this.$refs.cmGrid.getGridInstance.cellValue(data.rowIndex, data.columnIndex, this.password);
            this.password = '';
            this.rePassword = '';
            this.isOpenModal(false);
          }
        } else {
          this.$_Msg('비밀번호를 입력해주세요.');
        }
      },
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.password = '';
          this.rePassword = '';
          this.modal.currentComponent = null;
          this.modal.initData = {};
          this.modal.contentData = null;
        }
      },
      /** @description: 팝업 창 열때 이벤트 */
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      /** @description : Site의 id로 해당하는 tenant 목록 가져오는 메서드 */
      getTenantList(siteId) {
        return this.$store.getters.getTenantList.filter(tenant => tenant.siteId === siteId);
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @todo 패스워드 초기화 로직 추가 */
      onShowPasswordChange(options) {
        this.onOpenModal(
          'ModalCMPasswordChange',
          {
            title: `${options.data?.cmNm || ''} 교환기 비밀번호 변경`,
            //buttons: null,
            width: '500',
            height: '250',
            buttons: {
              save: { text: '저장' },
              cancel: { text: '닫기' },
            },
          },
          options,
        );
      },
      async selectDataList(sort = '+cmOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_CM_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {},
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
